<template>
  <div class="container">
<!-- 基础信息   -->
    <div class="container_base">
      <div class="container_base_info">基础信息</div>
      <div class="container_base_line"/>
      <div class="container_base_content">
        <el-upload
            accept=".jpg,.png,.JPG,.PNG,.jpeg"
            class="avatar-uploader"
            :action="uploadFileUrl"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :headers="headers"
            :on-error="handleUploadError"
            :on-success="handleSuccess"
            :on-exceed="handleExceed"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar"/>
          <el-icon v-else class="avatar-uploader-icon">
            <img src="../../assets/images/activity/upload.png" alt="">
            封面图片
          </el-icon>
        </el-upload>
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">活动标题</div>
            <el-input v-model="title" placeholder="请输入" clearable/>
          </div>
          <div class="container_base_center_title2">
            <div class="container_base_center_title2_name">积分类型</div>
            <el-select v-model="scoreType" clearable placeholder="请选择">
              <el-option
                  v-for="item in scoreTypeColumns"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">活动类别</div>
            <el-select v-model="typeId" clearable placeholder="请选择" @change="onConfirmType">
              <el-option
                  v-for="item in typeColumns"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
          <div class="container_base_center_title2">
            <div class="container_base_center_title2_name">积分数值</div>
            <el-input v-model="score" placeholder="请输入" clearable :disabled="scoreEdit===false"/>
          </div>
        </div>
      </div>
    </div>
<!-- 单位   -->
    <div class="container_base">
      <div class="container_base_info">主办单位、承办单位</div>
      <div class="container_base_line"/>
      <div class="container_base_content">
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">主办单位</div>
            <el-input v-model="hostUnit" placeholder="请输入" clearable/>
          </div>
          <div class="container_base_center_title2">
            <div class="container_base_center_title2_name">承办单位</div>
            <el-input v-model="undertakeUnit" placeholder="请输入" clearable/>
          </div>

        </div>
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">主办单位负责人</div>
            <el-input v-model="hostUnitPerson" placeholder="请输入" clearable/>
          </div>
          <div class="container_base_center_title2">
            <div class="container_base_center_title2_name">承办单位负责人</div>
            <el-input v-model="undertakeUnitPerson" placeholder="请输入" clearable/>
          </div>

        </div>
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">主办单位联系方式</div>
            <el-input v-model="hostUnitPhone" placeholder="请输入" clearable/>
          </div>
          <div class="container_base_center_title2">
            <div class="container_base_center_title2_name">承办单位联系方式</div>
            <el-input v-model="undertakeUnitPhone" placeholder="请输入" clearable/>
          </div>
        </div>

      </div>
    </div>
<!-- 时间地点   -->
    <div class="container_base">
      <div class="container_base_info">时间、地点</div>
      <div class="container_base_line"/>
      <div class="container_base_content">
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">活动时间</div>
            <el-date-picker
                v-model="time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm"
            />
          </div>
          <div class="container_base_center_title2">
            <div class="container_base_center_title2_name">活动地点</div>
            <el-input v-model="address" placeholder="请输入" clearable/>
          </div>
        </div>
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">报名截止时间</div>
            <el-date-picker
                v-model="signEndTime"
                type="datetime"
                placeholder="截止时间"
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm"
            />
          </div>
          <div class="container_base_center_title2">
            <div class="container_base_center_title2_name">详细地址</div>
            <el-input v-model="addressDetail" placeholder="请输入" clearable/>
          </div>

        </div>
        <div class="container_base_center">
          <div class="container_base_center_introduce">
            <div class="container_base_center_introduce_name">活动说明</div>
            <el-input
                resize='none'
                v-model="content"
                :rows="5"
                type="textarea"
                placeholder="请输入"
            />
          </div>
        </div>

      </div>
    </div>
<!-- 其他信息   -->
    <div class="container_base">
      <div class="container_base_info">其他信息</div>
      <div class="container_base_line"/>
      <div class="container_base_content">
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">活动费用</div>
            <el-input v-model="moneyName" placeholder="请输入" clearable/>
          </div>
          <div class="container_base_center_title2">
            <div class="container_base_center_title2_name">长期活动</div>
            <el-select v-model="isLong" clearable placeholder="请选择" @change="onConfirmLong">
              <el-option
                  v-for="item in longType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>

        </div>
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">参与人数限制</div>
            <el-input v-model="peopleNum" placeholder="请输入" clearable/>
          </div>
          <div class="container_base_center_title2" v-if="isLong=='1'">
            <div class="container_base_center_title2_name"></div>
            <el-button  type="primary" class="container_base_center_title2" @click="addData">添加子活动</el-button>
          </div>
<!--          <div class="container_base_center_title2" v-if="isLong=='1'">
            <div class="container_base_center_title2_name">活动周期</div>
            <el-select v-model="periodId" clearable placeholder="请选择">
              <el-option
                  v-for="item in period"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>-->

        </div>
        <div class="container_base_center">
          <div class="container_base_center_title">
            <div class="container_base_center_title_name">通知方式</div>
            <el-select v-model="noticeType" clearable placeholder="请选择">
              <el-option
                  v-for="item in noticeTypeColumns"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
<!--          <div class="container_base_center_title2" v-if="isLong=='1'&&periodId=='1'">
            <div class="container_base_center_title2_name">重复时间</div>
            <el-select v-model="repeatTimeMonth" multiple clearable placeholder="请选择">
              <el-option
                  v-for="item in monthOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
          <div class="container_base_center_title2" v-if="isLong=='1'&&periodId=='2'">
            <div class="container_base_center_title2_name">重复时间</div>
            <el-select v-model="repeatTimeWeek"  multiple clearable placeholder="请选择">
              <el-option
                  v-for="item in weekOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>-->

        </div>

      </div>
      <div class="container_base_child" v-if="childShow">
        <table style="border-collapse: collapse" class="container_base_child_table">
          <tr style="width: 100%;margin-bottom: 10px">
            <th style="width: 34%">子活动时间</th>
            <th style="width: 33%;text-align: left">子活动报名截止时间</th>
            <th style="width: 33%;"></th>
          </tr>
          <tr style="width: 100%;" v-for="(item,index) in childList" :key="index">
            <td style="width: 33%;text-align: left">
              <el-date-picker
                  v-model="item.time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="YYYY-MM-DD HH:mm"
                  value-format="YYYY-MM-DD HH:mm"
              />
            </td>
            <td style="width: 33%;text-align: left">
              <el-date-picker
                  v-model="item.signEndTime"
                  type="datetime"
                  placeholder="截止时间"
                  format="YYYY-MM-DD HH:mm"
                  value-format="YYYY-MM-DD HH:mm"
              />
            </td>
            <td style="width: 33%;text-align: center" @click="delData(index)">
              <el-button type="danger">删除</el-button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="container_btn">
      <el-button color="#FFC21E" @click="submit">发布活动</el-button>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue'
import {ElMessage} from 'element-plus'
import {getToken} from "@/utils/auth";
import {target} from "@/utils/URL"
const router = useRouter();
import {getActivityTypeList, getTypeScore, issueActivity} from "@/api/activity";
import {useRouter} from "vue-router";
const childList=ref([])
const childShow=ref(false)
const addData=()=>{
  childShow.value=true
  childList.value.push({
      time: '',
      signEndTime: ''
    })
}
const delData=(index)=>{
  childList.value.splice(index,1)
  if(childList.value.length==0){
    childShow.value=false
  }
}
const onConfirmLong = async() => {
  childList.value=[]
  if(isLong.value!=="1"){
    childShow.value=false
  }
}
const submit=async ()=>{
  if (!title.value) {
    ElMessage({
      message: '请输入活动标题',
      type:"error"
    })
    return false
  }

  if (!typeId.value) {
    ElMessage({
      message: '请选择活动类别',
      type:"error"
    })
    return false
  }

  if (!peopleNum.value) {
    ElMessage({
      message: '请输入参与人数',
      type:"error"
    })
    return false
  }
  if (!hostUnit.value) {
    ElMessage({
      message: '请输入主办单位',
      type:"error"
    })
    return false
  }
  if (!hostUnitPerson.value) {
    ElMessage({
      message: '请输入主办人',
      type:"error"
    })
    return false
  }
  if (!hostUnitPhone.value) {
    ElMessage({
      message: '请输入主办人电话',
      type:"error"
    })
    return false
  }
  if (!undertakeUnit.value) {
    ElMessage({
      message: '请输入协办单位',
      type:"error"
    })
    return false
  }
  if (!undertakeUnitPerson.value) {
    ElMessage({
      message: '请输入协办人',
      type:"error"
    })
    return false
  }
  if (!undertakeUnitPhone.value) {
    ElMessage({
      message: '请输入协办人电话',
      type:"error"
    })
    return false
  }
  if (!time.value) {
    ElMessage({
      message: '请选择活动时间',
      type: "error"
    })
    return false
  }

  if (!signEndTime.value) {
    ElMessage({
      message: '请选择报名截止时间',
      type:"error"
    })
    return false
  }
  if (!address.value) {
    ElMessage({
      message: '请输入活动地点',
      type:"error"
    })
    return false
  }
  if (!addressDetail.value) {
    ElMessage({
      message: '请输入详细地址',
      type:"error"
    })
    return false
  }
  if (!content.value) {
    ElMessage({
      message: '请输入活动内容',
      type:"error"
    })
    return false
  }
  if (!noticeType.value) {
    ElMessage({
      message: '请选择通知方式',
      type:"error"
    })
    return false
  }
  if(isLong.value=='1'){
    if(childList.value.length==0){
      ElMessage({
        message: '请添加子活动',
        type:"error"
      })
      return false
    }else {
      for (const e of childList.value) {
        if(e.time==''){
          ElMessage({
            message: '子活动时间不能为空',
            type:"error"
          })
          return false
        }
        if(e.signEndTime==''){
          ElMessage({
            message: '子活动报名截止时间不能为空',
            type:"error"
          })
          return false
        }
      }
    }
    /*if (!periodId.value) {
      ElMessage({
        message: '请选择活动周期',
        type:"error"
      })
      return false
    }

    if(periodId.value==="1"){
      if (!repeatTimeMonth.value) {
        ElMessage({
          message: '请选择重复时间',
          type:"error"
        })
        return false
      }
    }
    if(periodId.value==="2"){
      if (!repeatTimeWeek.value) {
        ElMessage({
          message: '请选择重复时间',
          type:"error"
        })
        return false
      }
    }*/
  }
  let data={
    name:title.value,
    picUrl:imageUrl.value,
    actTypeId:typeId.value,
    signUpLimit:stationId.value,
    limitNum:peopleNum.value,
    organizer:hostUnit.value,
    organizerUsername:hostUnitPerson.value,
    organizerPhone:hostUnitPhone.value,
    coOrganiser:undertakeUnit.value,
    coOrganizerUsername:undertakeUnitPerson.value,
    coOrganizerPhone:undertakeUnitPhone.value,
    money:(moneyName.value===''?"0":moneyName.value),
    scoreType:scoreType.value,
    score:(score.value===''?"0":score.value),
    time:time.value,
    signEndTime:signEndTime.value,
    address:address.value,
    addressDetail:addressDetail.value,
    content:content.value,
    noticeType:noticeType.value
  }
  if(isLong.value=='1'){
    data.longType="1";
    data.childActivityList=childList.value
    /*data.cycle=periodId.value
    if(periodId.value=='1'){
      data.repeatTime=repeatTimeMonth.value.toString()
    }else {
      data.repeatTime=repeatTimeWeek.value.toString()
    }*/
  }else {
    data.longType="0";
  }
  let response=await issueActivity(data);
  if(response){
    router.go(-1)
  }
  console.log(response)
}

//活动类别
const typeId = ref('')
const typeColumns = ref([]);
const getAcTypeList= async ()=>{
  let response=await getActivityTypeList();
  typeColumns.value=response.data
}
getAcTypeList()
//积分
const score = ref("")
const scoreEdit = ref(true)
const onConfirmType = async() => {
  let response=await getTypeScore(typeId.value);
  if(response.data.score==null){
    //输入框
    scoreEdit.value=true
    score.value=''
  }else {
    //选择
    score.value=response.data.score
    scoreEdit.value=false
  }
}
//主办单位
const hostUnit = ref('');
const hostUnitPerson = ref('');
const hostUnitPhone = ref('');
//承办单位
const undertakeUnit = ref('');
const undertakeUnitPerson = ref('');
const undertakeUnitPhone = ref('');
const content = ref('')
const time = ref('')
const title = ref("")
const moneyName = ref("")
const address = ref("")
const addressDetail = ref("")

const peopleNum = ref("")



const signEndTime = ref('')
const noticeType = ref('')

const stationId = ref('')


const scoreType = ref('')
const scoreTypeColumns = ref([
  {id: 1, name: '获得'},
  {id: 2, name: '消耗'}
])

const isLong = ref("0")
const longType = [
  {
    value: '1',
    label: '是',
  },
  {
    value: '0',
    label: '否',
  }
]

const noticeTypeColumns = [
  {
    value: '0',
    label: '无',
  },
  {
    value: '1',
    label: '短信',
  },
  {
    value: '2',
    label: '微信',
  },
]

const imageUrl = ref('')

const uploadFileUrl =ref(target + "common/upload")  // 上传的图片服务器地址
const headers=ref({
  Authorizationapp: getToken(),
})
const beforeUpload=(file)=> {
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    ElMessage.error('上传头像图片大小不能超过 2MB!');
  }
  return isLt2M;
}
// 图片文件个数超出
const handleExceed=()=> {
  ElMessage.error(`只允许上传单个文件`);
}
const handleSuccess=(res)=> {
  console.log(res)
  ElMessage.success("上传成功");
  imageUrl.value = res.url;
}
// 图片上传失败
const handleUploadError=() =>{
  ElMessage.error("上传失败, 请重试");
}

</script>

<style lang="scss" scoped>
th{
  line-height: 16px;
  color: rgba(132, 146, 166, 100);
  font-size: 16px;
  text-align: left;
  font-family: PingFangSC-regular;
}
::v-deep .el-textarea__inner{
  width: 350px;
  /*background-color: #F4F4F4;*/
  height: 156px;
}

::v-deep .el-input__wrapper {
  /*background-color: rgba(244, 244, 244, 100);*/
  height: 50px !important;
}
/*时间范围*/
::v-deep .el-date-editor.el-input__wrapper{
  width: 330px!important;
}
::v-deep .el-date-editor.el-input{
  height: 50px !important;
  width: 350px!important;
}
.container {
  background-color: #F4F4F4;
  padding: 40px 375px 80px 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_btn{
    margin-top: 60px;
    .el-button{
      width: 350px;
      height: 60px;
      border-radius: 6px;
      line-height: 20px;
      color: rgba(59, 47, 0, 100);
      font-size: 20px;
      font-family: PingFangSC-semiBold;
      font-weight: bolder;
    }
  }

  &_base {
    background-color: #FFFFFF;
    width: 1170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 30px;
    &_child{
      width: 1110px;
      margin-top: 30px;
      line-height: 20px;
      font-size: 20px;
      &_table{
        width: 100%;
        padding: 30px;
      }
    }
    &_info {
      width: 1110px;
      margin-left: 45px;
      margin-top: 30px;
      line-height: 20px;
      color: rgba(255, 98, 0, 100);
      font-size: 20px;
      text-align: left;
      font-family: PingFangSC-semiBold;
      font-weight: bolder;
    }

    &_line {
      margin-top: 30px;
      width: 1110px;
      height: 1px;
      line-height: 20px;
      border-radius: 6px;
      background-color: rgba(245, 245, 245, 100);
      color: rgba(16, 16, 16, 100);
      font-size: 14px;
      text-align: center;
      font-family: Roboto;
    }

    &_content {
      width: 1110px;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &_center {

      &_title2 {
        margin-top: 30px;

        &_name {
          margin-bottom: 10px;
          margin-left: 15px;
          line-height: 16px;
          color: rgba(132, 146, 166, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-regular;
        }

        .el-input {
          width: 350px;
          line-height: 20px;
          border-radius: 2px;

          text-align: center;
        }

        .el-select {
          width: 350px;
          line-height: 50px;
          border-radius: 2px;
          text-align: center;
        }
      }

      &_title {
        &_name {
          margin-bottom: 10px;
          margin-left: 15px;
          line-height: 16px;
          color: rgba(132, 146, 166, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-regular;
        }

        .el-input {
          width: 350px;
          line-height: 20px;
          border-radius: 2px;

          text-align: center;
        }

        .el-select {
          width: 350px;
          line-height: 50px;
          border-radius: 2px;
          text-align: center;
        }
        .el-date-picker{
          width: 350px !important;
          line-height: 50px;
          border-radius: 2px;
          text-align: center;
        }
      }

      &_introduce{
        &_name {
          margin-bottom: 10px;
          margin-left: 15px;
          line-height: 16px;
          color: rgba(132, 146, 166, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-regular;
        }
        .el-input {
          width: 350px;
          line-height: 20px;
          border-radius: 2px;
          text-align: center;
        }
      }
      &_bottom {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        &_type {
          width: 160px;

          &_name {
            margin-bottom: 10px;
            margin-left: 15px;
            line-height: 16px;
            color: rgba(132, 146, 166, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-regular;
          }
        }
      }
    }
  }

  &_unit {
    margin-top: 20px;
    width: 1170px;
    height: 323px;
    line-height: 20px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
  }
}

.avatar-uploader .avatar {
  width: 350px;
  height: 182px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 350px;
  height: 182px;
  text-align: center;
  border: 1px solid #8c939d;
}
</style>
